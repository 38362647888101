/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useContext } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Slide from 'components/Slider/SingleSlide'
import Content from 'components/shared/Content'
import leftArrow from 'assets/icons/Arrow_left.svg'
import rightArrow from 'assets/icons/Arrow_right.svg'
import downArrow from 'assets/icons/Arrow_down.svg'
import Icon from 'components/shared/Icon'
import scrollToSection from 'utils/scrollToSection'
import Carousel from 'react-slick'
import { LangContext } from 'contexts/LangContext'
import useMedia from 'hooks/useMedia'

const Wrapper = styled.header`
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 500px;
  overflow: hidden;
  ${({ centered }) =>
    centered &&
    css`
      width: auto;
      height: auto;
    `}
`

const InnerContent = styled(Content)`
  max-width: 1500px;
`

const InnerWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`

const ControlBtn = styled.div`
  display: ${({ alwaysVisible }) => (alwaysVisible ? 'block' : 'none')};
  position: absolute;
  top: ${({ arrowsPosition }) => arrowsPosition};
  transform: ${({ arrowsPosition }) => `translateY(-${arrowsPosition})`};
  z-index: 8;
  background-color: rgba(0, 0, 0.4);
  width: 150px;
  height: 100px;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.red};
  }
  ${({ theme }) => theme.mq.md} {
    display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  }
`

const Prev = styled(ControlBtn)`
  left: -60px;
  background: url(${leftArrow}) no-repeat;
  background-position: 65% 50%;
  background-color: rgba(0, 0, 0, 0.4);
`

const Next = styled(ControlBtn)`
  right: -60px;
  background: url(${rightArrow}) no-repeat;
  background-position: 35% 50%;
  background-color: rgba(0, 0, 0, 0.4);
`

const IconWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  display: none;
  flex-direction: column;
  align-items: center;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  ${({ theme }) => theme.mq.md} {
    display: flex;
  }
`

const Info = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.bold};
  text-transform: uppercase;
  text-align: center;
  padding: 30px 0;
  line-height: 20px;
  ${({ theme }) => theme.mq.md} {
    text-align: left;
    max-width: 300px;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 600px;
  }
`

const defaultSettings = {
  infinite: true,
  draggable: false,
  slidesToShow: 1,
  speed: 900,
}

const Slider = ({
  arrowsPosition,
  carousel,
  settings,
  items,
  info,
  scrollTo,
}) => {
  const sliderRef = useRef(null)
  const { lang } = useContext(LangContext)

  const slideToPrev = () => {
    sliderRef.current.slickPrev()
  }

  const slideToNext = () => {
    sliderRef.current.slickNext()
  }

  const slides = carousel
    ? items
    : items.map(({ src, alt, highlightedText, text }) => (
        <Slide
          key={text}
          highlightedText={highlightedText}
          image={src}
          imageAlt={alt}
          text={text}
        />
      ))

  const matchesLG = useMedia('(min-width: 1000px)')

  const sliderSettings = settings || defaultSettings

  return (
    <Wrapper centered={carousel}>
      {carousel !== 'centered' && carousel ? (
        <InnerContent>
          <Carousel ref={sliderRef} {...sliderSettings}>
            {slides}
          </Carousel>
        </InnerContent>
      ) : (
        <Carousel ref={sliderRef} {...sliderSettings}>
          {slides}
        </Carousel>
      )}
      <Prev
        arrowsPosition={arrowsPosition}
        isHidden={
          (slides.length <= 2 && carousel && matchesLG) ||
          (!carousel && slides.length < 2)
        }
        alwaysVisible={carousel === 'centered'}
        onClick={slideToPrev}
      />
      <Next
        arrowsPosition={arrowsPosition}
        isHidden={
          (slides.length <= 2 && carousel && matchesLG) ||
          (!carousel && slides.length < 2)
        }
        alwaysVisible={carousel === 'centered'}
        onClick={slideToNext}
      />
      {info && (
        <InnerWrapper>
          <Content>
            <Info>{info}</Info>
          </Content>
        </InnerWrapper>
      )}
      {scrollTo && (
        <IconWrapper onClick={() => scrollToSection(scrollTo)}>
          <span>
            {lang === 'EN' && 'MORE'}
            {lang === 'DE' && 'MEHR'}
          </span>
          <Icon src={downArrow} alt="more" />
        </IconWrapper>
      )}
    </Wrapper>
  )
}

Slider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  settings: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  info: PropTypes.string,
  arrowsPosition: PropTypes.string,
  scrollTo: PropTypes.string,
  carousel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

Slider.defaultProps = {
  info: '',
  scrollTo: '',
  carousel: '',
  settings: null,
  arrowsPosition: '50%',
}

export default Slider
