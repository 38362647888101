import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Icon from 'components/shared/Icon'
import LazyImage from 'components/shared/LazyImage'
import dotsSvg from 'assets/icons/dots-before.svg'
import dotsSvg2 from 'assets/icons/dots-after.svg'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 600px;
  object-fit: cover;
  overflow: hidden;
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const TextWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  left: 20%;
  transform: translate(-20%, -50%);
  z-index: 3;
  ${({ theme }) => theme.mq.lg} {
    left: 35%;
    transform: translate(-35%, -50%);
  }
`

const Text = styled.h1`
  display: block;
  width: 300px;
  position: relative;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.red};
  font-weight: ${({ theme }) => theme.superBold};
  font-size: 5.2rem;
  text-shadow: rgba(49, 49, 49, 0.7) 1px 0 10px;
  ${({ theme }) => theme.mq.xs} {
    width: auto;
  }
  ${({ theme }) => theme.mq.s} {
    font-size: ${({ theme }) => theme.fontSize.xxxl};
  }
  ${({ theme }) => theme.mq.lg} {
    white-space: nowrap;
  }
  ${({ small }) =>
    small &&
    css`
      font-size: 3.4rem;
      ${({ theme }) => theme.mq.xs} {
        font-size: 4.4rem;
      }
      ${({ theme }) => theme.mq.s} {
        font-size: ${({ theme }) => theme.fontSize.xxl};
      }
      ${({ theme }) => theme.mq.md} {
        font-size: ${({ theme }) => theme.fontSize.xxxl};
      }
    `}
`

const IconWrapper = styled.div`
  position: absolute;
  width: 70px;
  height: 100%;
  z-index: 2;
  top: -85px;
  left: 5px;
  pointer-events: none;
  ${({ theme }) => theme.mq.s} {
    top: -100px;
  }
  ${({ theme }) => theme.mq.lg} {
    display: block;
    top: 50%;
    left: -100px;
    transform: translateY(-50%);
  }
`

const IconWrapper2 = styled(IconWrapper)`
  width: 250px;
  top: 90px;
  ${({ theme }) => theme.mq.s} {
    top: 110px;
  }
  ${({ theme }) => theme.mq.lg} {
    top: 50%;
    left: auto;
    right: -300px;
  }
`

const Highlight = styled.span`
  color: ${({ theme }) => theme.colors.white};
`

const SingleSlide = ({ image, imageAlt, highlightedText, text }) => (
  <Wrapper>
    <TextWrapper>
      {highlightedText ? (
        <Text small={highlightedText.length > 10}>
          <IconWrapper>
            <Icon full src={dotsSvg} alt="dots" />
          </IconWrapper>
          <IconWrapper2>
            <Icon full src={dotsSvg2} alt="dots" />
          </IconWrapper2>
          <Highlight>{`${highlightedText} `}</Highlight>
          {text}
        </Text>
      ) : (
        <Text>{text}</Text>
      )}
    </TextWrapper>
    <ImageWrapper>
      <LazyImage
        minHeight="600px"
        objectPosition="70% 50%"
        slideImage
        src={image}
        alt={imageAlt}
        cover
      />
    </ImageWrapper>
  </Wrapper>
)

SingleSlide.propTypes = {
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  imageAlt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  highlightedText: PropTypes.string,
}

SingleSlide.defaultProps = {
  highlightedText: '',
}

export default SingleSlide
